<template>
  <div class="preview-failed d-flex justify-content-center">
    <ErrorMessage
      :message="
        errorMessage == 'no-products' ? $t(`general.no-products`) : errorMessage
      "
    />
  </div>
</template>

<script>
import ErrorMessage from "./ErrorMessage.vue";

export default {
  name: "ProductPreviewFailed",
  components: {
    ErrorMessage,
  },
  props: {
    errorMessage: {
      type: String,
      default: "no-products",
    },
  },
};
</script>
