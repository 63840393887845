<template>
  <div class="tbl-info">
    <div class="count">
      <template v-if="countInfo">
        {{
          $t(countInfo, {
            from_limit: fromLimit,
            to_limit: toLimit,
            total_count: totalRecords,
          })
        }}
      </template>
      <template v-else>
        Showing {{ fromLimit }} to {{ toLimit }} of {{ totalRecords }}
        {{ recordsTitle }}
      </template>
    </div>
    <!-- pagination-export  -->
    <div class="d-flex justify-content-between pagination-actions mt-2 mt-md-0">
      <b-link
        class="export-csv my-md-0 my-2"
        v-if="showExportButton"
        :disabled="totalRecords == '00'"
        @click="$emit('downloadData')"
        >{{ $t(`general.export-csv`) }}
        <i class="icon-download ml-1"></i>
      </b-link>
      <div class="pagination" :class="{ 'ml-4': showExportButton }">
        <b-link
          class="btn-previous"
          :disabled="totalRecords == '00' || currentPage == 1"
          @click="$emit('paginate', 'previous')"
        >
          <i class="icon-angle-left mr-2"></i
          >{{ $t(`general.previous`) }}</b-link
        >
        <b-link
          class="btn-next ml-3"
          :disabled="totalRecords == '00' || currentPage == totalPages"
          @click="$emit('paginate', 'next')"
          >{{ $t(`general.next`) }}
          <i class="icon-angle-right ml-2"></i>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  props: {
    currentPage: {
      type: [String, Number],
      default: 1,
    },
    totalPages: {
      type: [String, Number],
      default: 1,
    },
    fromLimit: {
      type: [String, Number],
      default: 0,
    },
    toLimit: {
      type: [String, Number],
      default: 0,
    },
    totalRecords: {
      type: [String, Number],
      default: 0,
    },
    showExportButton: {
      type: Boolean,
      default: false,
    },
    recordsTitle: {
      type: String,
      default: "products",
    },
    countInfo: {
      type: String,
    },
  },
};
</script>
